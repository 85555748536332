<template>
	<div class="grid grid-cols-1 gap-6 px-3 pb-12 xl:px-0">
		<div
			v-for="(row, i) in rows"
			:key="i"
			class="items-center justify-center hidden w-full h-20 space-x-12 xl:flex"
		>
			<div
				v-for="(sponsor, j) in row"
				:key="j"
			>
				<img
					:src="sponsor.src"
					:alt="sponsor.alt"
					:width="sponsor.width"
					:height="sponsor.height"
				>
			</div>
		</div>
		<div class="flex flex-wrap w-full pt-8 gap-x-12 gap-y-4 justify-evenly xl:hidden">
			<div
				v-for="(sponsor, i) in SPONSOR_LOGOS"
				:key="i"
				class="flex h-20"
			>
				<img
					:src="sponsor.src"
					:alt="sponsor.alt"
					:width="sponsor.width"
					:height="sponsor.height"
					class="my-auto"
				>
			</div>
		</div>
	</div>
</template>

<script async>
import SPONSOR_LOGOS from '@/constants/dabvent/sponsor-logos.js'

export default {
	name: 'DabventSponsors',
	data() {
		return {
			SPONSOR_LOGOS
		}
	},
	computed: {
		firstRow() {
			return SPONSOR_LOGOS.slice(0, 1)
		},
		secondRow() {
			return SPONSOR_LOGOS.slice(1, 5)
		},
		thirdRow() {
			return SPONSOR_LOGOS.slice(5)
		},
		rows() {
			return [ this.firstRow, this.secondRow, this.thirdRow ]
		}
	}
}
</script>
