export const SPONSOR_LOGOS = [
	{
		alt: 'Ganja Vacations - Jamaica',
		src: require('@/assets/events/dabvent/GanjaVacations.png')
	},
	{
		alt: 'Blazy Susan',
		src: require('@/assets/events/dabvent/BlazySusan.png')
	},
	{
		alt: 'Puffco',
		src: require('@/assets/events/dabvent/PuffCo.png')
	},
	{
		alt: 'High Cool Cat',
		src: require('@/assets/events/dabvent/HighCoolCat.png')
	},
	{
		alt: 'Leafwell',
		src: require('@/assets/events/dabvent/Leafwell.png')
	},
	{
		alt: 'Levo',
		src: require('@/assets/events/dabvent/Levo.png')
	},
	{
		alt: 'Ispire',
		src: require('@/assets/events/dabvent/Ispire.png')
	},
	{
		alt: 'Haute Box',
		src: require('@/assets/events/dabvent/HauteBox.png')
	}
]

export default SPONSOR_LOGOS
