<template>
	<div class="relative flex-shrink-0 w-[208px] transition-all duration-100 bg-transparent h-[277px] rounded-xl snap-center">
		<div
			class="absolute top-0 left-0 w-full h-full transition-all duration-200 shadow-xl cursor-pointer rounded-xl shadow-yellow-500"
			:style="{ background: unOpenedGradient }"
			@click="handleCardClick"
		>
			<div class="relative w-full h-full">
				<LeafIcon
					fill-opacity="0.6"
					:class="[
						'absolute w-full transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 z-2 animate-pulse'
					]"
				/>
				<LeafIcon class="absolute w-full transform scale-75 -translate-x-1/2 -translate-y-1/2 bg-yellow-500 rounded-full top-1/2 left-1/2 z-1 blur-2xl animate-pulse opacity-70" />
				<div class="absolute inset-x-0 top-0 grid grid-cols-1 text-center z-3">
					<div class="'text-white pt-[100px] font-bold text-[14px]">
						{{ card.title }}
					</div>
					<div class="'text-white pt-2 font-bold uppercase text-[25px]">
						{{ card.text }}
					</div>
				</div>
				<img
					:src="bowImage"
					alt="Bow"
					:height="bowImageHeight"
					:width="bowImageWidth"
					class="absolute top-0 left-0 transform rounded-tl-xl z-5"
				>
			</div>
		</div>
	</div>
</template>

<script async>
import LeafIcon from '@/components/icons/LeafIcon.vue'

export default {
	components: {
		LeafIcon
	},
	props: {
		card: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			leafBackgroundImg: require('@/assets/backgrounds/white-leaf.svg'),
			unOpenedGradient: 'linear-gradient(180deg, #A03333 0%, #FC3333 100.08%)',
			bowImage: require('@/assets/illustrations/gift-bow.svg'),
			bowImageHeight: 103,
			bowImageWidth: 117
		}
	},
	methods: {
		handleCardClick() {
			// #TODO: Share Links
		}
	}
}
</script>
