<template>
	<DefaultLayout>
		<div class="bg-[#035046] relative flex flex-col items-center flex-auto h-full text-center text-white transition-all duration-300 ease-in-out bg-center bg-cover">
			<div
				:style="{ backgroundImage: `url(${heroBackgroundImg})` }"
				class="absolute inset-0 w-full bg-center bg-cover opacity-80 h-96"
			/>
			<div class="w-full max-w-6xl py-4 m-auto">
				<div class="text-lg">
					<div class="px-4">
						<img
							:src="DABVENT_HEADER_TEXT"
							alt="2022 Dabvent Calendar"
							:height="195"
							:width="800"
							class="w-full max-w-4xl mx-auto mt-3 md:mt-8"
						>
						<p class="mt-6 text-xl font-semibold xl:mt-8">
							{{ intro }}
						</p>
					</div>
					<DabventCarousel
						v-if="eventIsActive"
						:event-details="EVENT_DETAILS"
					/>
					<DabventStaticCard
						v-if="eventIsUpcoming"
						:card="staticCard"
						class="mx-auto mt-8 xl:mt-12"
					/>
					<p
						:class="[
							'max-w-4xl px-4 mx-auto',
							{'pt-7': eventIsOver},
							{'pt-12 xl:pt-16': eventIsUpcoming},
							{'md:pt-0 xl:px-0': eventIsActive}
						]"
					>
						<span>
							<span>
								In order to celebrate the season, we’re giving you the chance to win 12 days of discounts and prizes. <span class="font-bold text-yellow-500">
									Come back daily from December 20 to December 31,
								</span> for prizes from some of the best brands in cannabis, including <span class="font-semibold">
									Puffco, Ispire, High Cool Cat, LĒVO, Blazy Susan, and more!
								</span>
							</span>
							<span class="mt-2">
								<span>
									<span class="font-bold text-yellow-500">
										Stay tuned for the
										grand prize on December 31
									</span>
								</span> <span class="font-semibold text-yellow-500">
									- for a <span class="font-bold uppercase">
										free
									</span>
									4-day/3-night stay at HotBox Resort in Ochos Rios, Jamaica from GanjaVacations!
								</span>
							</span>
						</span>
					</p>
					<div
						v-if="eventIsOver"
						class="max-w-sm mx-auto mt-8"
					>
						<div class="mb-6 text-4xl font-bold">
							{{ eventOverHeading }}
						</div>
						<div class="mb-6 text-2xl font-bold">
							{{ eventOverSubheading }}
						</div>
					</div>
					<div
						:class="[
							'items-center justify-center px-4 mx-auto space-y-4 leading-5 xl:mb-12 xl:px-0',
							{'pt-8 md:pt-4': eventIsUpcoming},
							{'pt-2': eventIsOver},
							{'pt-4': eventIsActive}
						]"
					>
						<SocialShare>
							<template #default="{ share }">
								<div class="max-w-sm mx-auto mb-2">
									{{ shareCTA }}
								</div>
								<div class="space-x-2">
									<button @click="share">
										<InstagramIcon class="w-5 h-5" />
									</button>
									<button @click="share">
										<FacebookIcon class="w-5 h-5" />
									</button>
									<button @click="share">
										<TwitterIcon class="w-5 h-5" />
									</button>
								</div>
							</template>
						</SocialShare>
					</div>
					<DabventSponsors />
				</div>
			</div>
		</div>
	</DefaultLayout>
</template>

<script async>
import DabventCarousel from '@/components/dabvent/DabventCarousel.vue'
import DabventSponsors from '@/components/dabvent/DabventSponsors.vue'
import DabventStaticCard from '@/components/dabvent/DabventStaticCard.vue'
import FacebookIcon from '@/components/icons/FacebookIcon.vue'
import InstagramIcon from '@/components/icons/InstagramIcon.vue'
import TwitterIcon from '@/components/icons/TwitterIcon.vue'
import SocialShare from '@/components/multiUse/SocialShare.vue'
import { useDabvent } from '@/composables/dabvent.js'
import { EVENT_DETAILS } from '@/constants/dabvent/event-details.js'
import DefaultLayout from '@/layouts/default/Index.vue'

const { isDayBeforeEvent, isDayAfterEvent, setInitialDays } = useDabvent()

const DABVENT_HEADER_TEXT = require('@/assets/events/dabvent/DabventHeaderText.svg')

export default {
	components: {
		DefaultLayout,
		DabventCarousel,
		DabventStaticCard,
		DabventSponsors,
		SocialShare,
		FacebookIcon,
		TwitterIcon,
		InstagramIcon
	},
	metaInfo() {
		return {
			title: '2022 Dabvent Calendar - Where\'s Weed',
			description: this.description
		}
	},
	data() {
		return {
			heroBackgroundImg: require('@/assets/backgrounds/leaf-header-background-2.svg'),
			intro: 'Happy Holidaze from Where’s Weed!',
			description: 'In order to celebrate the season, we’re giving you the chance to win 12 days of discounts and prizes. Come back daily from December 20 to December 31, for prizes from some of the best brands in cannabis, including Puffco, Ispire, High Cool Cat, LĒVO, Blazy Susan, and more! Stay tuned for the grand prize on December 31 – a free 4-day/3-night stay at HotBox Resort in Ochos Rios, Jamaica from GanjaVacations!',
			eventOverHeading: 'This event is now over.',
			eventOverSubheading: 'State tuned for our next event.',
			eventBeforeHeading: 'This event is not yet active.',
			staticCard: {
				id: '1',
				title: 'Open your first gift',
				text: 'Starting December 20th'
			},
			EVENT_DETAILS,
			DABVENT_HEADER_TEXT
		}
	},
	computed: {
		eventIsActive() {
			return !this.eventIsOver && !this.eventIsUpcoming
		},
		eventIsOver() {
			return isDayAfterEvent(EVENT_DETAILS)
		},
		eventIsUpcoming() {
			return isDayBeforeEvent(EVENT_DETAILS)
		},
		shareCTA() {
			if (this.eventIsActive || this.eventIsUpcoming) return 'Thanks for visiting! Make sure to share with your friends!'
			return 'Follow us to stay up to date on events and cannabis related information.'
		}
	},
	mounted() {
		setInitialDays(EVENT_DETAILS)
	}
}
</script>
