import { ref } from 'vue'

import dateFormat from '@/utils/dateFormat.js'

const dabventDays = ref([])
const error = ref('')
const todayDate = new Date()
let eventStorageKey = ''

export function useDabvent (eventDetails) {
	const updateStorage = (event) => {
		if (!window || !window?.localStorage) return false
		localStorage.setItem(eventStorageKey, JSON.stringify(dabventDays.value))
	}

	const setInitialDays = (eventDetails) => {
		eventStorageKey = `dabvent-${eventDetails.startDate.getFullYear()}-${eventDetails.startDate.getMonth()}`

		if (todayDate < eventDetails.startDate) {
			error.value = `Please come back in ${dateFormat(eventDetails.startDate)} for our ${eventDetails.name}.`
		}
		if (!localStorage?.getItem(eventStorageKey)) {
			for (let i = 0; i < eventDetails.daysRunning; i++) {
				const daysDate = new Date(eventDetails.startDate)
				daysDate.setDate(eventDetails.startDate.getDate() + i)
				const month = daysDate.toLocaleDateString('en-us', { month: 'long' })
				const date = daysDate.getDate()
				dabventDays.value.push({ day: daysDate, viewed: false, month: month, date: date })
			}
		} else {
			dabventDays.value = JSON.parse(localStorage.getItem(eventStorageKey))
		}
		updateStorage()
	}

	const getDayOfEvent = (eventDetails) => {
		const daysSinceStart = Math.floor((todayDate - eventDetails.startDate) / (1000 * 60 * 60 * 24))
		if (daysSinceStart < 0) return 0
		return daysSinceStart
	}

	const isDayViewable = (day) => {
		const dayDate = new Date(day)
		return dayDate <= todayDate
	}

	const isDayBeforeEvent = (eventDetails) => {
		return todayDate < eventDetails.startDate
	}

	const isDayAfterEvent = (eventDetails) => {
		const endDate = new Date(eventDetails.startDate)
		endDate.setDate(eventDetails.startDate.getDate() + eventDetails.daysRunning)
		if (!dabventDays.value?.length) return false
		return todayDate > endDate
	}

	const setViewed = (day) => {
		dabventDays.value.find(entry => entry.day === day).viewed = true
		updateStorage()
	}

	const reset = (eventDetails) => {
		dabventDays.value = []
		localStorage.removeItem(eventStorageKey)
		setInitialDays(eventDetails)
	}

	return { setViewed, reset, dabventDays, error, isDayViewable, isDayBeforeEvent, isDayAfterEvent, setInitialDays, getDayOfEvent }
}
