var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DefaultLayout',[_c('div',{staticClass:"bg-[#035046] relative flex flex-col items-center flex-auto h-full text-center text-white transition-all duration-300 ease-in-out bg-center bg-cover"},[_c('div',{staticClass:"absolute inset-0 w-full bg-center bg-cover opacity-80 h-96",style:({ backgroundImage: `url(${_vm.heroBackgroundImg})` })}),_vm._v(" "),_c('div',{staticClass:"w-full max-w-6xl py-4 m-auto"},[_c('div',{staticClass:"text-lg"},[_c('div',{staticClass:"px-4"},[_c('img',{staticClass:"w-full max-w-4xl mx-auto mt-3 md:mt-8",attrs:{"src":_vm.DABVENT_HEADER_TEXT,"alt":"2022 Dabvent Calendar","height":195,"width":800}}),_vm._v(" "),_c('p',{staticClass:"mt-6 text-xl font-semibold xl:mt-8"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.intro)+"\n\t\t\t\t\t")])]),_vm._v(" "),(_vm.eventIsActive)?_c('DabventCarousel',{attrs:{"event-details":_vm.EVENT_DETAILS}}):_vm._e(),_vm._v(" "),(_vm.eventIsUpcoming)?_c('DabventStaticCard',{staticClass:"mx-auto mt-8 xl:mt-12",attrs:{"card":_vm.staticCard}}):_vm._e(),_vm._v(" "),_c('p',{class:[
						'max-w-4xl px-4 mx-auto',
						{'pt-7': _vm.eventIsOver},
						{'pt-12 xl:pt-16': _vm.eventIsUpcoming},
						{'md:pt-0 xl:px-0': _vm.eventIsActive}
					]},[_c('span',[_c('span',[_vm._v("\n\t\t\t\t\t\t\tIn order to celebrate the season, we’re giving you the chance to win 12 days of discounts and prizes. "),_c('span',{staticClass:"font-bold text-yellow-500"},[_vm._v("\n\t\t\t\t\t\t\t\tCome back daily from December 20 to December 31,\n\t\t\t\t\t\t\t")]),_vm._v(" for prizes from some of the best brands in cannabis, including "),_c('span',{staticClass:"font-semibold"},[_vm._v("\n\t\t\t\t\t\t\t\tPuffco, Ispire, High Cool Cat, LĒVO, Blazy Susan, and more!\n\t\t\t\t\t\t\t")])]),_vm._v(" "),_c('span',{staticClass:"mt-2"},[_c('span',[_c('span',{staticClass:"font-bold text-yellow-500"},[_vm._v("\n\t\t\t\t\t\t\t\t\tStay tuned for the\n\t\t\t\t\t\t\t\t\tgrand prize on December 31\n\t\t\t\t\t\t\t\t")])]),_vm._v(" "),_c('span',{staticClass:"font-semibold text-yellow-500"},[_vm._v("\n\t\t\t\t\t\t\t\t- for a "),_c('span',{staticClass:"font-bold uppercase"},[_vm._v("\n\t\t\t\t\t\t\t\t\tfree\n\t\t\t\t\t\t\t\t")]),_vm._v("\n\t\t\t\t\t\t\t\t4-day/3-night stay at HotBox Resort in Ochos Rios, Jamaica from GanjaVacations!\n\t\t\t\t\t\t\t")])])])]),_vm._v(" "),(_vm.eventIsOver)?_c('div',{staticClass:"max-w-sm mx-auto mt-8"},[_c('div',{staticClass:"mb-6 text-4xl font-bold"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.eventOverHeading)+"\n\t\t\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"mb-6 text-2xl font-bold"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.eventOverSubheading)+"\n\t\t\t\t\t")])]):_vm._e(),_vm._v(" "),_c('div',{class:[
						'items-center justify-center px-4 mx-auto space-y-4 leading-5 xl:mb-12 xl:px-0',
						{'pt-8 md:pt-4': _vm.eventIsUpcoming},
						{'pt-2': _vm.eventIsOver},
						{'pt-4': _vm.eventIsActive}
					]},[_c('SocialShare',{scopedSlots:_vm._u([{key:"default",fn:function({ share }){return [_c('div',{staticClass:"max-w-sm mx-auto mb-2"},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.shareCTA)+"\n\t\t\t\t\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"space-x-2"},[_c('button',{on:{"click":share}},[_c('InstagramIcon',{staticClass:"w-5 h-5"})],1),_vm._v(" "),_c('button',{on:{"click":share}},[_c('FacebookIcon',{staticClass:"w-5 h-5"})],1),_vm._v(" "),_c('button',{on:{"click":share}},[_c('TwitterIcon',{staticClass:"w-5 h-5"})],1)])]}}])})],1),_vm._v(" "),_c('DabventSponsors')],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }