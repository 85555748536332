export const EVENT_CARDS = [
	{ // 20th
		title: 'LEVO Discount Code',
		embed: `<a class="e-widget no-button generic-loader" href="https://gleam.io/QjVxy/dabvent-day-1" rel="nofollow">Dabvent Day 1</a>
		<script type="text/javascript" src="https://widget.gleamjs.io/e.js" async="true"></script>`
	},
	{ // 21st
		title: 'Cannabis Product Giveaway - High Cool Cat 70s set',
		embed: `<a class="e-widget no-button generic-loader" href="https://gleam.io/N92l6/dabvent-day-2-high-cool-cat-70s-set" rel="nofollow">Dabvent Day 2: High Cool Cat 70s Set</a>
		<script type="text/javascript" src="https://widget.gleamjs.io/e.js" async="true"></script>`
	},
	{ // 22nd
		title: 'Munchie Food photo contest  - win taco bell gift card',
		embed: `<a class="e-widget no-button generic-loader" href="https://gleam.io/RDIrG/dabvent-day-3-favorite-munchie-foods" rel="nofollow">Dabvent Day 3: Favorite Munchie Foods</a>
		<script type="text/javascript" src="https://widget.gleamjs.io/e.js" async="true"></script>`
	},
	{ // 23rd
		title: 'Swag bag & sticker pack Sweepstakes',
		embed: `<a class="e-widget no-button generic-loader" href="https://gleam.io/9MPwQ/dabvent-day-4-wheres-weed-swag-sticker-pack" rel="nofollow">Dabvent Day 4: Where's Weed Swag & Sticker Pack</a>
		<script type="text/javascript" src="https://widget.gleamjs.io/e.js" async="true"></script>`
	},
	{ // 24th
		title: 'Discount Code - Blazy Susan',
		embed: `<a class="e-widget no-button generic-loader" href="https://gleam.io/X2YwO/dabvent-day-4" rel="nofollow">Dabvent Day 4</a>
		<script type="text/javascript" src="https://widget.gleamjs.io/e.js" async="true"></script>`
	},
	{ // 25th
		title: 'Cannabis Product Sweepstakes - Puffco Proxy',
		embed: `<a class="e-widget no-button generic-loader" href="https://gleam.io/pofMC/dabvent-day-6-puffco-giveaway" rel="nofollow">Dabvent Day 6: Puffco Giveaway</a>
		<script type="text/javascript" src="https://widget.gleamjs.io/e.js" async="true"></script>`
	},
	{ // 26th
		title: 'Best rolled joint photo contest – win a set of unique rolling papers from High Cool Cat',
		embed: `<a class="e-widget no-button generic-loader" href="https://gleam.io/ufKgu/dabvent-day-7-best-rolled-joint-photo-contest" rel="nofollow">Dabvent Day 7: Best Rolled Joint Photo Contest</a>
		<script type="text/javascript" src="https://widget.gleamjs.io/e.js" async="true"></script>`
	},
	{ // 27th
		title: 'Swag bag & sticker pack Sweepstakes',
		embed: `<a class="e-widget no-button generic-loader" href="https://gleam.io/WYnD7/dabvent-day-8-wheres-weed-swag-sticker-pack" rel="nofollow">Dabvent Day 8: Where's Weed Swag & Sticker Pack</a>
		<script type="text/javascript" src="https://widget.gleamjs.io/e.js" async="true"></script>`
	},
	{ // 28th
		title: 'Discount code - Leafwell',
		embed: `<a class="e-widget no-button generic-loader" href="https://gleam.io/X8NGk/dabvent-day-9" rel="nofollow">Dabvent Day 9</a>
		<script type="text/javascript" src="https://widget.gleamjs.io/e.js" async="true"></script>`
	},
	{ // 29th
		title: 'Cannabis Product Sweepstakes - Ispire Daab',
		embed: `<a class="e-widget no-button generic-loader" href="https://gleam.io/DUbda/dabvent-day-10-ispire-giveaway" rel="nofollow">Dabvent Day 10: Ispire Giveaway</a>
		<script type="text/javascript" src="https://widget.gleamjs.io/e.js" async="true"></script>`
	},
	{ // 30th
		title: 'Cannabis Product Sweepstakes - Hautebox (mini, regular & large hautebox)',
		embed: `<a class="e-widget no-button generic-loader" href="https://gleam.io/lCGYc/dabvent-day-11-hautebox-giveaway" rel="nofollow">Dabvent Day 11: Hautebox Giveaway</a>
		<script type="text/javascript" src="https://widget.gleamjs.io/e.js" async="true"></script>`
	},
	{ // 31st
		title: 'Ganjavacations Sweepstakes - free Jamaican vacay for 2, airfare not included ',
		embed: `<a class="e-widget no-button generic-loader" href="https://gleam.io/EEMQM/ganjavacations-x-wheres-weed-jamaica-giveaway" rel="nofollow">Ganjavacations x Where's Weed Jamaica Giveaway</a>
		<script type="text/javascript" src="https://widget.gleamjs.io/e.js" async="true"></script>`
	}
]

export const EVENT_DAY_COUNT = EVENT_CARDS.length

export const EVENT_DETAILS = {
	startDate: new Date('2022-12-20T00:00:00'), // 2022-12-13T00:00:00 - change the month and day to test the before and after event states
	name: 'Dabvent Calendar',
	daysRunning: EVENT_DAY_COUNT
}

export default { EVENT_DETAILS, EVENT_CARDS, EVENT_DAY_COUNT }
